import {
    Aggregate, ColumnDirective, ColumnsDirective, CommandClickEventArgs, CommandColumn,
    CommandModel,
    Edit,
    ExcelExport,
    Filter, FilterSettingsModel, GridComponent, Inject, PdfExport,
    Resize,
    SelectionSettingsModel,
    Sort,
    Toolbar, ToolbarItems, SearchSettingsModel, SearchEventArgs, Search,
    Group,
    AggregatesDirective,
    AggregateDirective,
    AggregateColumnsDirective,
    AggregateColumnDirective
} from "@syncfusion/ej2-react-grids";
import { Predicate, Query } from '@syncfusion/ej2-data';
import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { loadFromApi } from "../../../api/baseApi";
import {useCommonGridOptions } from "../../../utils/gridUtils";
import { camelCaseToString } from "../../../utils/stringUtils";
import LoadingDisplay from "../../common/LoadingDisplay";
import MessagePopup from "../../common/MessagePopup";
import { GridViewContext } from "../../GridViewService";
import { AuthContext } from "../../../authentication/authContext";
import {DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getCreditorNameById, getScheduledPayments, getRejectData, updateAccountData, updateAmountData  } from "../../../api/scheduledPaymentsApi";
import { DateTime } from "luxon";
export const ScheduledPayments = () => {

    const { addToast } = useToasts();
    const roleid = localStorage.getItem('id');
    const { currentView } = React.useContext(GridViewContext);
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [scheduledPayments, setScheduledPayments] = useState<any[]>([]);
    const [reject, setReject] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [columns, setColumns] = useState<any>([]);
    const [creditorName, setCreditorName] = useState('');
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const chaseAchCreditorId = 77;
    const [loadScheduledPaymentsGrid, setLoadScheduledPaymentsGrid] = useState(false);

    useEffect(() => {
        let data: any  = {};
        let columns = Object.values(scheduledPayments)[0] ? Object.keys(Object.values(scheduledPayments)[0]) : [];
        if((roleid ?? loggedInUser?.creditorId) == chaseAchCreditorId){
            columns = columns.filter((col) => col != 'userId' && col != 'restrictChanges' && col != 'creditorName' && col != 'primaryContactName' 
        && col != 'secondaryContactName' && col != 'primaryContactSsn' && col != 'secondaryContactSsn' && col != 'primaryContactAddress' && col != 'rejected'
        && col != 'reference' )
        }
        else
        {
        columns = columns.filter((col) => col != 'userId' && col != 'restrictChanges' && col != 'creditorName' && col != 'primaryContactName' 
        && col != 'secondaryContactName' && col != 'primaryContactSsn' && col != 'secondaryContactSsn' && col != 'primaryContactAddress' && col != 'rejected'
        && col != 'payeeName' && col != 'payeeAddress' && col != 'rejected')
        }
        columns.length > 0 && setColumns(columns);
        scheduledPayments.map((row : any)=> {
            let amnt = Number(row.amount.replace('$', '').replace(',', ''));
            if(data.hasOwnProperty(row.bankCode)){
                data[row.bankCode] = amnt + data[row.bankCode]
            }
            else {
                data[row.bankCode] = amnt
            }
        })
    }, [scheduledPayments]);
    const [isAccountUpdated, setIsAccountUpdated] = useState(false);
    const [userInfo, setUserInfo] = useState(undefined);
    const updateAccount  =(data: any) => {
        let roleid= localStorage.getItem('id');
        if (data.account.length !== 16 && (roleid ?? loggedInUser?.creditorId) == chaseAchCreditorId) {
            addToast('Account Number must be 16 characters long', { appearance: 'error', autoDismiss: true });
            return;
        }
        setLoading(true)
        const ScheduledPaymentsApiWrapper = loadFromApi(setApiError);
        const loadUpdateAccountData = async () => {  
                     
            await ScheduledPaymentsApiWrapper(() =>updateAccountData(data), setIsAccountUpdated);
            loadCompanyPageData();
            
        };               
        loadUpdateAccountData();
        const loadCompanyPageData = async () => { 

            await ScheduledPaymentsApiWrapper(()=>getScheduledPayments(roleid ?? loggedInUser?.creditorId, startDate, endDate), setScheduledPayments);
        };               
         
        setLoading(false)
    }

    const fetchUserInfo = (data: any) => {
        setLoading(true)
        setUserInfo(data)
        setModalType('UserInfo')
        setShowDialog(true);
        setLoading(false)
    }

    const [isAmounttUpdated, setIsAmountUpdated] = useState(false);
    const updateAmount  =(data: any) => {
        let roleid= localStorage.getItem('id');
        const ScheduledPaymentsApiWrapper = loadFromApi(setApiError);
        const loadUpdateAmountData = async () => {  
            if ((roleid ?? loggedInUser?.creditorId) == chaseAchCreditorId) {
                addToast('You do not have permission to update the amount. Please contact support for assistance.', { appearance: 'error', autoDismiss: true });
                return;
            }         
            await ScheduledPaymentsApiWrapper(() =>updateAmountData({...data, userID: userId}), setIsAmountUpdated);
            loadCompanyPageData()
        };               
        loadUpdateAmountData();
        const loadCompanyPageData = async () => { 

            await ScheduledPaymentsApiWrapper(()=>getScheduledPayments(roleid ?? loggedInUser?.creditorId, startDate, endDate), setScheduledPayments);
            
        };  
    }

    useEffect(() => {
        setLoading(true)
        const ScheduledPaymentsApiWrapper = loadFromApi(setApiError);
        let roleid= localStorage.getItem('id');
        const loadCreditorName = async () => { 

            await ScheduledPaymentsApiWrapper(()=>getCreditorNameById(roleid ?? loggedInUser?.creditorId), setCreditorName);
            
        };  
        loadCreditorName();
        setLoading(false)
    }, [setApiError]);
    const [isRejected, setIsRejected] = useState(false)
    const [modalType, setModalType] = useState('')
    const [editedRow, setEditedRow] = useState({});
    const [bankData, setBankData] = useState({});
    const {  loggedInUser} = React.useContext(AuthContext);
    useEffect(() => {
        setIsRejected(false);
        if(reject){
            const ScheduledPaymentsApiWrapper = loadFromApi(setApiError);
            const rejectData = async () => {            
              await ScheduledPaymentsApiWrapper(() => getRejectData({
                    settlementId:id,
                    changedBy:  loggedInUser?.userId,
                    rejectReason: rejectReason,
                    userId: userId
                }), setIsRejected)
            };      
            rejectData()   
        }        
    },[reject])

    useEffect(()=> {
        if(isRejected){
            let data: any = scheduledPayments.map((col: any) => {
                if(id && col.transactionID === id){
                    col.rejected = true;
                }
                return col;
            });
            setRejectValue('hy')
            setScheduledPayments(data);  
        }
    }, [isRejected])

    const onRejectClick = (args?: any) => {
        if(!args.rejected){
            setShowDialog(true); 
            setModalType('Reject')
            if(args?.transactionID ){
                setId(args?.transactionID); 
                setUserId(args?.userId);   
            } 
        }
    };
    const [rejectValue, setRejectValue] = useState('Reject');
    const editCommand: CommandModel[] = [
        {
            title: 'Edit',
            type: 'Edit',            
            buttonOption: {
                cssClass: 'e-outline edit-icon', 
            }
        }
    ];
    const selectionSettings: SelectionSettingsModel = { type: 'Multiple', checkboxOnly: true };
    const [id, setId] = useState('');
    const [userId, setUserId] = useState('')
    let dataBound = () =>{
        if(gridOptions.ref.current)
            {
        gridOptions.ref.current.groupModule.collapseAll();
            }
        if(gridOptions.ref.current && currentView === 2)
        {
                gridOptions.ref.current.autoFitColumns([])
        }
        let tbody    = document.getElementsByClassName('e-content')[0].getElementsByClassName('e-table')[0].getElementsByTagName('tbody')[0];
        let trs = tbody.getElementsByTagName('tr');
        let headerTrs = [];
        for(let i=0;i<trs.length;i++){
            if(trs[i].classList.value.includes('e-firstchildrow')){
                headerTrs.push(trs[i-1]); 
            }
        }
        let groupdAggregatedata: any=[];
        gridOptions.ref.current.currentViewData.map((data: any)=>{
                groupdAggregatedata.push(data)
        })
        headerTrs.forEach((header, id)=>{
            header.getElementsByTagName('td')[1].setAttribute('colspan', '3')
            let start = 10;
    
                let tdd = header.insertCell();
                let span = document.createElement('span');
                    {
                    span.innerText = 'Amount Due $'+ Number(groupdAggregatedata[id].aggregates[`amount - sum`]).toFixed(2);
                }
                tdd.appendChild(span);           
                tdd.classList.add('e-templatecell');
                tdd.classList.add('e-summarycell');
                tdd.classList.add('e-ellipsistooltip');
                tdd.tabIndex = -1;
                tdd?.setAttribute('index',start.toString())
                tdd?.setAttribute('role','gridcell')
                tdd?.setAttribute('colspan','6')
        })
    }

    const onCommandClick = (args?: CommandClickEventArgs) => {
       
        if (args && args.rowData && args.target ) {
            if((args.target.className.includes('edit-icon'))){
                setEditedRow(args.rowData)
                setUserId((args.rowData as any).userId) 
                setModalType('Edit')
                setShowDialog(true)
            }
            else{
                if(!(args.rowData as any).rejected){
                    setShowDialog(true); 
                    setModalType('Reject')
                    if((args.rowData as any).transactionID ){
                        setId((args.rowData as any).transactionID);  
                    } 
                }
            }
           
            const consumerData = args.rowData ;
        }
    };
    var exportFileName = creditorName+ '_Scheduled Payments_' + startDate + '-' + endDate;
    const gridOptions: any = useCommonGridOptions(exportFileName);
    const formatRejectedValue = (field: string, data?: any) => {
        let val = '';
        if(data){
            val = data['rejected'] === true ? 'Reject' : 'Rejected';
        }
        setRejectValue(val);
        return val;
    };
    const [loading, setLoading] = useState(false);
    const [enableConfirm, setEnableConfirm] = useState(false);
    const onRowSelected = () => {
        let ref = gridOptions.ref;
        if(ref.current?.getSelectedRecords() &&
            ref.current?.getSelectedRecords().length > 0){
            
            setEnableConfirm(true)
        }else {
            setEnableConfirm(false)
        }
    }

    const searchOptions: SearchSettingsModel = {fields: [
        'clientName',
        'payeeAccountNumber'
      ],
      key: ''};
    const toolbarOptions: ToolbarItems[] = ['ExcelExport', 'PdfExport', 'CsvExport','Search'];

    let values: any;
    let key = '';
    let refresh = false;
    let removeQuery = false;
    let valueAssign = false;

    const actionBegin = (args: SearchEventArgs) => {
        if (args.requestType == 'searching') {
          const keys = args?.searchString?.split(',');
          var flag = true;
          var predicate: any;
          if (keys && keys?.length > 1) {
            if (gridOptions.ref.current.searchSettings.key !== '') {
              values = args.searchString;
              keys.forEach((key) => {
                key=key.trim();
                gridOptions.ref.current.getColumns().forEach((col: any) => {
                if(key.length > 1){
                  if (flag) {
                    predicate = new Predicate(col.field, 'contains', key, true);
                    flag = false;
                  } 
                  else {
                    var predic = new Predicate(col.field, 'contains', key, true);
                    predicate = predicate.or(predic);
                  }
                }
                });
              });
              gridOptions.ref.current.query = new Query().where(predicate);
              gridOptions.ref.current.searchSettings.key = '';
              refresh = true;
              valueAssign = true;
              removeQuery = true;
              gridOptions.ref.current.refresh();
            }
          }
        }
    }

   const actionComplete = (args: SearchEventArgs) => {
        if (args.requestType === 'refresh' && valueAssign) {
            if(gridOptions?.ref?.current )
             (document.getElementById(gridOptions?.ref?.current?.element.id + '_searchbar') as any).value = values;
          valueAssign = false;
        } else if (
            gridOptions?.ref?.current && (document.getElementById(gridOptions?.ref?.current?.element.id + '_searchbar') as any)?.value === '' &&
          args.requestType === 'refresh' &&
          removeQuery
        ) {
          gridOptions.ref.current.query = new Query();
          removeQuery = false;
          gridOptions.ref.current.refresh();
        }
      }


    const onRowDataBound = (args?: any) => {
        if (args && args.data) {
            let data = args.data as any;
            if (data.restrictChanges || data.rejected) {
                args.row
                ?.getElementsByClassName('e-editbutton')[0]
                    .classList.add('disabled');
            }
        }
    };
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    const {allowExcelExport, allowPdfExport, toolbarClick, pdfQueryCellInfo, excelQueryCellInfo} = gridOptions;

    const apiError = useCallback(
        (error?: string) => (error=='' || error) && addToast('We are currently unable to complete verification. Please contact our support team for more information.', { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    
    const groupOptions = {
        columns: ['paymentDueDate'],
        showDropArea: false,
        showGroupedColumn: false,
    };
    const loadScheduledPayments = () => {
        setLoadScheduledPaymentsGrid(true)
        setLoading(true)
        let vendorId= localStorage.getItem('id');
        const scheduledPaymentsApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => {
            await scheduledPaymentsApiWrapper(()=>getScheduledPayments(vendorId ?? loggedInUser?.creditorId, startDate, endDate), setScheduledPayments);
            setLoading(false) ;
        };               
        loadCompanyPageData();  
    }
    const footerSum=(props:any):any=>
        {
            return(
                <span>
                {props.Sum}
                </span>
            )
        }
        const handleDateChange = (args?: any) => {
            if (args.startDate && args.endDate) {
                let startDate = new Date(args.startDate);
                let endDate = new Date(args.endDate);
                setStartDate(`${startDate.getMonth()+1}/${startDate.getDate().toString().length === 1 ? '0'+startDate.getDate() : startDate.getDate()}/${startDate.getFullYear()}`);
                setEndDate(`${endDate.getMonth()+1}/${endDate.getDate().toString().length === 1 ? '0'+endDate.getDate() : endDate.getDate()}/${endDate.getFullYear()}`);
            }
        };
        return <> <div className="intent-tag">
            <div><h3 className="intent-name">Creditor Name :</h3> <span>{creditorName}</span></div>
            <div className="date-picker">
            <label>Date Range:</label>
            <DateRangePickerComponent
                        id="date-range"
                        placeholder="Select Date Range"
                        change={handleDateChange}
                        min={(roleid ?? loggedInUser?.creditorId) != chaseAchCreditorId ? new Date(new Date().setDate(new Date().getDate() + 2)) : new Date()}
                        max={(roleid ?? loggedInUser?.creditorId) == chaseAchCreditorId ? new Date(new Date().setDate(new Date().getDate() + 14)) : undefined}
                        strictMode={true}
                    />
            </div>
        <button onClick={loadScheduledPayments}>Load Scheduled Payments</button>
            </div>
        { loading  ? (
            <LoadingDisplay />
        ) : <div className={`intent-page`}>  
        <div className="deposit-details">
        {loadScheduledPaymentsGrid && <h3>Payment Queue Details</h3>}
        </div>
        {loadScheduledPaymentsGrid && (<div className="future-payments-grid">
            <span className='e-icons e-close serach-clear' onClick={()=>{
            if (gridOptions.ref.current) {
                gridOptions.ref.current.searchSettings.key = '';
                (document.getElementById(gridOptions?.ref?.current?.element.id + '_searchbar') as any).value = '';
                gridOptions.ref.current.refresh();
            }
        }}></span>
         <GridComponent
         searchSettings={searchOptions}
        width={'1500px'}
        actionComplete={actionComplete}
        allowExcelExport={allowExcelExport}
        pdfQueryCellInfo={pdfQueryCellInfo}
        excelQueryCellInfo={excelQueryCellInfo}
        allowPdfExport={allowPdfExport}
        actionBegin={actionBegin}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        ref={gridOptions.ref}
        allowFiltering={true}
        allowSorting={true}
        filterSettings={filterOptions}
        rowHeight={currentView === 3 ? 20 : 40}
        selectionSettings={selectionSettings}
        rowSelected={onRowSelected}
        rowDeselected={onRowSelected}
        rowDataBound={onRowDataBound}
        dataBound= { dataBound } 
        commandClick={onCommandClick}
        dataSource={scheduledPayments}
        clipMode='EllipsisWithTooltip'
        allowGrouping={true}
        groupSettings={groupOptions}
    >
        
            
            <ColumnsDirective>
                 <ColumnDirective
                    headerText=""
                    field="btnEdit"
                    commands={editCommand}
                    textAlign="Left"
                    width='4.5%'
                    headerTextAlign="Left"
                    allowFiltering={false}
                > </ColumnDirective> 
                {(roleid ?? loggedInUser?.creditorId) != chaseAchCreditorId && <ColumnDirective
                    headerText=""
                    field="rejected"
                    textAlign="Left"
                    width='7%'
                    template= {(props: any) => { 
                        return <button className={props['rejected'] ?  'rejected-icon':'reject-icon'} 
                        onClick={()=>onRejectClick(props)} disabled = {props.restrictChanges}>{!props['rejected'] ? 'Reject' : 'Rejected'} </button>
                    }}
                    headerTextAlign="Left"
                    allowFiltering={false}
                > </ColumnDirective> }
                
                {
                    columns.map((key: string, index: number)=>{
                    const header = camelCaseToString(key);
                        return <ColumnDirective
                            key={index}
                            field={key}
                            allowFiltering={true}
                            headerText={header.substring(0,1).toUpperCase()+
                            header.substring(1)}
                            width="100px"
                            template={key==='rejected' ?(props: any) => { 
                                return <button className={props['rejected'] ?  'rejected-icon':'reject-icon'} 
                                onClick={()=>onRejectClick(props)} disabled = {props.restrictChanges}>{!props['rejected'] ? 'Reject' : 'Rejected'} </button>
                            } : (key==='clientName' ? (props: any) => <a
                            onClick={()=> {
                                fetchUserInfo(props)
                            }}
                            href="javascript:void(0);"
                            >{props[key]}</a>: undefined)}
                        >
                        </ColumnDirective>
                    })
                }
                
                 
                    
            </ColumnsDirective>
            <AggregatesDirective>
                    <AggregateDirective >
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective format="C2"
                         field='amount'  type='Sum' footerTemplate={footerSum}/>
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
        <Inject services={[Edit, Search, CommandColumn, PdfExport, ExcelExport, Toolbar, Filter,Aggregate,Resize, Sort, Group]} />
    </GridComponent></div>)}</div>}

      {showDialog && <MessagePopup
        requireConfirmation={modalType === 'Reject' ? true : false}
        message={modalType === 'Reject' ? "Are you sure you want to reject this payment?" : undefined}
        showDialog= {showDialog}
        type={modalType}
        updateAccount={updateAccount}
        updateAmount={updateAmount}
        data = {modalType === 'Edit' ? editedRow : (modalType === 'UserInfo' ? userInfo : bankData)}
        setShowDialog={(val)=>{
            setShowDialog(val)
        }}
        onConfirmClick={(reason?:string) => {
            setReject(false);
            if(modalType!=='Edit'){
                setRejectReason(reason ?? '');
                setReject(true);
            }
            setShowDialog(false)
         }}
        onCancelClick={() => {
            if(modalType!=='Edit'){
                setReject(false)
            }
            setShowDialog(false)
        }}
    ></MessagePopup>} </>
}